export default class ProductionProcess {
  constructor(cont) {
    this.processContainer = cont;
    this.bar = document.querySelector(".inner-bar");
    this.stages = document.querySelectorAll(".stage");
    this.isRunning = false;
    this.width = 0;
    this.timer = 0;
    this.time = 13000;
    this.step = this.time / this.stages.length;
    this.progressInt = null;
    this.index = 1;
    this.init();
  }

  init() {
    window.addEventListener("scroll", () => {
      if (this.processContainer.getBoundingClientRect().top < 380) {
        if (this.isRunning == false) {
          this.startPresentation();
          this.isRunning = true;
        }
      }
    });
  }

  startPresentation() {
    this.stages[0].classList.add("active");

    this.progressInt = setInterval(() => this.animateBar(), 10);
  }

  animateBar() {
    if (this.timer == this.time) {
      clearInterval(this.progressInt);
    } else {
      this.timer += 10;
      this.width = (this.timer / this.time) * 100;
      this.bar.style.width = this.width + "%";

      if (this.width == this.index * 20) {
        let j = this.index - 1;
        this.stages[j].classList.remove("active");
        this.stages[j].classList.add("done");
        this.stages[this.index].classList.add("active");
        this.index++;

        if (this.index == this.stages.length) {
          setTimeout(() => {
            this.stages[5].classList.remove("active");
            this.stages[5].classList.add("done");

            this.stages.forEach(stage => {
              stage.addEventListener("click", () => {
                stage.classList.toggle("active");
              });
            });
          }, this.step);
        }
      }
    }
  }
}
