// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import { isMobile } from "./helper";

/****** SLIDERS *******/
const sliders = {
	home: $(".homepage-slides-container"),
	about: $(".about-slider-container"),
	values: $(".our-values-container"),
	numbers: $(".company-in-numbers-mobile-inner"),
	categories: $(".MainCategories__listItemSlider"),
};

sliders.categories.slick({
	autoplay: true,
	vertical: true,
	dots: false,
	slidesToShow: 1,
	arrows: false,
});

sliders.home.slick({
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false,
	dots: true,
	dotsClass: "slider-dots",
});

sliders.about.slick({
	autoplay: true,
	autoplaySpeed: 10000,
	arrows: false,
	dots: true,
	dotsClass: "slider-dots",
	pauseOnHover: false,
	pauseOnFocus: false,
});

if (isMobile()) {
	sliders.values.slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		dotsClass: "slider-dots",
	});
}

sliders.numbers.slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	dots: false,
	slidesToShow: 2,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 415,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});
