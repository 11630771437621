export default class DisplayNumbers {
  constructor() {
    this.elements = document.querySelectorAll(".row > div");
    this.interval = null;
    this.randomElement = Math.floor(Math.random() * this.elements.length);

    if (this.elements.length > 0) {
      this.elements[this.randomElement].classList.add("active");

      this.interval = window.setInterval(() => {
        this.changeElement();
      }, 5000);
    }
  }

  changeElement() {
    this.elements.forEach(element => {
      element.classList.remove("active");
    });

    this.randomElement = Math.floor(Math.random() * this.elements.length);
    this.elements[this.randomElement].classList.add("active");
  }
}
