export default class Chat {
  constructor() {
    this.chatButton = document.getElementById("open-chat");
    this.chatWindow = document.querySelector(".chat-form");
    this.init();
  }

  init() {
    this.chatButton.addEventListener("click", e => {
      e.preventDefault();
      this.chatWindow.classList.toggle("open");
    });
  }
}
