import { isMobile, seoTextsHandler } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import ProductionProcess from "./productionProcess";
import Chat from "./chat";
import DisplayNumbers from "./displayNumbers";
import $ from "jquery";

const showPopup = window.localStorage.getItem("data-showPopup");
const popupClose = document.querySelector(".Popup__close");

if (showPopup === "false") {
  popupClose.parentElement.classList.add("Popup--hidden");
} else {
  popupClose.parentElement.classList.remove("Popup--hidden");
}

if (popupClose) {
  popupClose.addEventListener("click", () => {
    popupClose.parentElement.classList.add("Popup--hidden");
    window.localStorage.setItem("data-showPopup", "false");
  });
}

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();
  new Chat();

  const cont = document.querySelector(".production-process");
  if (cont) {
    new ProductionProcess(cont);
  }

  const seoTexts = document.querySelector(".seo-texts-container");
  if (seoTexts) {
    seoTextsHandler(seoTexts);
  }

  const numbers = document.querySelector(".company-in-numbers");
  if (numbers) {
    $(".count").each(function() {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 3000,
            easing: "swing",
            step: function(now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  }

  if (!isMobile()) {
    new DisplayNumbers();
  }

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();

    const numbersMobile = document.querySelectorAll(
      ".company-in-numbers-mobile .cinm-item"
    );
    if (numbersMobile) {
      numbersMobile.forEach((number) => {
        const textToBeReplaced = number.innerText.match(/[0-9]+(%|km)?,?/)[0];
        const newText = `<span class="helper-display-block">${textToBeReplaced}</span>`;
        const oldContent = number.innerHTML;
        const newContent = oldContent.replace(textToBeReplaced, newText);
        number.innerHTML = newContent;
      });
    }
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
